import { useState } from 'react';
import './App.css'

function Home() {
  const [sidb, setsidb] = useState("");
  return (
<>
  <div id="cryptoon-layout" className="theme-orange">
    {/* sidebar */}
    <div className={`sidebar py-2 py-md-2 me-0 border-end ${sidb}`}>
      <div className="d-flex flex-column h-100">
        {/* Logo */}
        <a className="mb-0 brand-icon">
          <img src='/logo.png' style={{width: "150px"}} />
        </a>
        {/* Menu: main ul */}
        <ul className="menu-list flex-grow-1 mt-4 px-1">
          <li>
            <a className="m-link active" href="index.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 38 38"
              >
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M34,18.756V34H22v-8h-6v8h-4V14.31l7-3.89L34,18.756z M34,16.472V6h-6v7.139L34,16.472z"
                  style={{ fill: "var(--primary-color)" }}
                  data-st="fill:var(--chart-color4);"
                />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  className="st0"
                  d="M34,14.19V6h-6v2h4v5.08L19,5.86L0.51,16.13l0.98,1.74L19,8.14l17.51,9.73l0.98-1.74L34,14.19z M32,32h-8v-8H14  v8H6V17.653l-2,1.111V34h12v-8h6v8h12V18.764l-2-1.111V32z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Dashboard</h6>
                <small className="text-muted">Analytics Report</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="exchange.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 38 38"
              >
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M20,25c0-1.698,0-6.334,0-11c0-4.418-1.582-8-6-8c-2.083,0-4.072,0.888-5.538,2.335  C5.708,11.053,4,14.826,4,19c0,8.284,6.716,15,15,15c2.736,0,5.294-0.745,7.503-2.025C22.87,31.719,20,28.698,20,25z"
                  style={{ fill: "var(--primary-color)" }}
                  data-st="fill:var(--chart-color4);"
                />
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  className="st0"
                  d="M15,11l-1,0.01c0,0,0,0,0-0.01H15z M22,0.24v2.04C29.95,3.69,36,10.65,36,19c0,4.17-1.52,8.01-4.03,10.97  l-0.02-0.02C30.68,31.22,28.93,32,27,32c-2.79,0-5.2-1.64-6.32-4H24l2-2h-5.92C20.02,25.67,20,25.34,20,25s0.02-0.67,0.08-1H28l2-2  h-9.32c1.12-2.36,3.53-4,6.32-4c1.93,0,3.68,0.78,4.95,2.05l1.41-1.41C31.73,17.01,29.48,16,27,16c-3.91,0-7.25,2.51-8.48,6H16v2  h2.06C18.02,24.33,18,24.66,18,25s0.02,0.67,0.06,1H16v2h2.52c1.23,3.48,4.56,5.99,8.46,6C24.6,35.28,21.88,36,19,36  C9.63,36,2,28.37,2,19c0-6.07,3.2-11.41,8-14.41V6.1C8.24,6.44,6,7.72,6,11c0,2.78,2.64,3.44,4.76,3.97C12.96,15.52,14,15.9,14,17  c0,2.82-2.5,2.99-2.99,3C10.5,19.99,8,19.82,8,17H6c0,3.28,2.24,4.56,4,4.9V24h2v-2.1c1.76-0.341,4-1.62,4-4.9  c0-2.78-2.64-3.44-4.76-3.97C9.04,12.48,8,12.1,8,11c0-2.82,2.5-2.99,3-3c2.81,0,2.99,2.48,3,3h2c0-1.57-0.86-4.42-4-4.91V3.52  C14.13,2.54,16.51,2,19,2c0.34,0,0.67,0.01,1,0.03V0.02C19.67,0.01,19.33,0,19,0C8.52,0,0,8.52,0,19c0,10.48,8.52,19,19,19  c10.48,0,19-8.52,19-19C38,9.54,31.06,1.68,22,0.24z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Exchange</h6>
                <small className="text-muted">Crypto asset Exchange</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="market.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <linearGradient
                  id="crp_svg"
                  gradientUnits="userSpaceOnUse"
                  x1="13.876"
                  y1="13.876"
                  x2="50.1249"
                  y2="50.1249"
                >
                  <stop offset={0} className="st2" />
                  <stop offset={1} className="st3" />
                </linearGradient>
                <polygon
                  className="st1"
                  points="50,34 50,30 39.465,30 55.517,20.732 53.518,17.269 37.464,26.537 42.732,17.412 39.268,15.412 
                                  34,24.536 34,6 30,6 30,24.536 24.732,15.412 21.268,17.413 26.536,26.536 10.483,17.268 8.483,20.732 24.535,30 14,30 14,34 
                                  24.537,34 8.483,43.269 10.483,46.732 26.536,37.465 21.268,46.589 24.732,48.589 30,39.464 30,58 34,58 34,39.465 39.268,48.589 
                                  42.732,46.589 37.465,37.465 53.517,46.732 55.517,43.269 39.463,34 	"
                />
                <path
                  className="st0"
                  d="M50,36c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S52.209,36,50,36z M36,6c0,2.209-1.791,4-4,4
                                  s-4-1.791-4-4s1.791-4,4-4S36,3.791,36,6z M36,58c0,2.209-1.791,4-4,4s-4-1.791-4-4s1.791-4,4-4S36,55.791,36,58z M18,32
                                  c0-2.209-1.791-4-4-4s-4,1.791-4,4s1.791,4,4,4S18,34.209,18,32z M44.464,18.412c-1.104,1.913-3.552,2.568-5.464,1.464
                                  c-1.914-1.104-2.568-3.551-1.465-5.464c1.105-1.913,3.551-2.569,5.465-1.464C44.912,14.052,45.568,16.499,44.464,18.412z
                                  M11.483,15.536c1.913,1.104,2.569,3.551,1.464,5.464s-3.551,2.568-5.464,1.464S4.915,18.914,6.02,17S9.57,14.432,11.483,15.536z
                                  M56.517,41.536c1.913,1.104,2.568,3.551,1.464,5.464s-3.551,2.569-5.464,1.465S49.948,44.913,51.053,43
                                  S54.604,40.432,56.517,41.536z M25,44.124c-1.913-1.104-4.36-0.448-5.465,1.464c-1.104,1.913-0.448,4.36,1.465,5.465
                                  s4.359,0.448,5.464-1.465C27.568,47.676,26.913,45.229,25,44.124z M26.464,14.412c1.104,1.913,0.448,4.36-1.465,5.464
                                  s-4.359,0.449-5.464-1.464s-0.449-4.359,1.464-5.464S25.359,12.499,26.464,14.412z M7.483,41.536
                                  c1.913-1.104,4.36-0.449,5.465,1.464s0.448,4.36-1.465,5.465C9.571,49.569,7.125,48.913,6.02,47S5.57,42.641,7.483,41.536z
                                  M52.517,15.536c1.913-1.104,4.359-0.449,5.464,1.464s0.45,4.36-1.463,5.464s-4.36,0.448-5.465-1.465S50.604,16.641,52.517,15.536z
                                  M39,44.124c-1.914,1.104-2.568,3.552-1.465,5.465s3.552,2.568,5.465,1.464s2.568-3.552,1.463-5.465
                                  C43.359,43.676,40.912,43.02,39,44.124z M38,32c0,3.313-2.687,6-6,6s-6-2.687-6-6s2.687-6,6-6S38,28.687,38,32z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Market</h6>
                <small className="text-muted">Market CryptoPrice</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="ico.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st1"
                  d="M20,57.921c-0.37,0-0.741-0.096-1.072-0.288L6.071,50.206C5.408,49.823,5,49.116,5,48.352V33.506
                                  c0-0.765,0.409-1.473,1.071-1.856l12.857-7.422c0.663-0.383,1.479-0.383,2.143,0l12.856,7.422C34.592,32.033,35,32.741,35,33.506
                                  v14.846c0,0.765-0.408,1.472-1.072,1.854l-12.856,7.427C20.741,57.825,20.37,57.921,20,57.921z M11,46.125l9,5.198l9-5.198V35.732
                                  l-9-5.194l-9,5.194V46.125z M44,57.919c-0.371,0-0.739-0.096-1.072-0.287L30.071,50.21C29.409,49.827,29,49.12,29,48.352V33.508
                                  c0-0.765,0.408-1.473,1.071-1.856l12.857-7.427c0.664-0.383,1.48-0.383,2.145,0l12.855,7.427C58.592,32.035,59,32.743,59,33.508
                                  v14.844c0,0.769-0.408,1.476-1.072,1.858l-12.855,7.422C44.739,57.823,44.371,57.919,44,57.919z M35,46.125l9,5.196l9-5.196V35.732
                                  l-9-5.198l-9,5.198V46.125z M32,37.135c-0.371,0-0.739-0.096-1.072-0.287l-12.856-7.422C17.409,29.042,17,28.335,17,27.567V12.724
                                  c0-0.766,0.408-1.474,1.071-1.856L30.928,3.44c0.664-0.383,1.48-0.383,2.145,0l12.855,7.427C46.592,11.25,47,11.958,47,12.724
                                  v14.844c0,0.768-0.408,1.475-1.072,1.858l-12.855,7.422C32.739,37.039,32.371,37.135,32,37.135z M23,25.341l9,5.196l9-5.196V14.948
                                  L32,9.75l-9,5.198V25.341z"
                />
                <path
                  className="st0"
                  d="M31.997,51.321l-13.926-8.046C17.408,42.893,17,42.184,17,41.419V25.341l13.929-8.044
                                  c0.663-0.383,1.479-0.383,2.144,0L47,25.339v16.08c0,0.765-0.408,1.474-1.072,1.856L31.997,51.321z M23,39.195l9,5.198l9-5.198
                                  V28.802l-9-5.194l-9,5.194V39.195z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Ico</h6>
                <small className="text-muted">Initial Coin Offering</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="future-trade.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st0"
                  d="M21.972,34.25c0-4.906,3.495-8.992,8.13-9.912l-3.492-7.764L14.317,40.282l8.673-1.635
                                  C22.345,37.315,21.972,35.828,21.972,34.25z M41.17,38.647l8.673,1.635L37.55,16.574l-3.491,7.764
                                  c4.635,0.92,8.129,5.006,8.129,9.912C42.188,35.828,41.815,37.315,41.17,38.647z M32.08,26.968c-3.875,0-7.016,3.141-7.016,7.016
                                  c0,3.874,3.141,7.016,7.016,7.016c3.875,0,7.018-3.142,7.018-7.016C39.098,30.109,35.955,26.968,32.08,26.968z"
                />
                <path
                  className="st1"
                  d="M60.001,32c0.001,15.461-12.539,28-28.003,28C16.538,60,4,47.462,4.001,32.002C3.997,16.537,16.536,4,31.996,4
                                  C47.46,4,60,16.537,60.001,32L60.001,32z M54.001,32C54,19.851,44.144,10,31.996,10c-12.145,0-21.999,9.85-21.995,22.001
                                  C10,44.146,19.852,54,31.998,54C44.146,54,54.002,44.145,54.001,32L54.001,32z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Future</h6>
                <small className="text-muted">Future Trade</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="p2p.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st1"
                  d="M28,52h8v4h-8V52z M16,56h8v-4h-8V56z M4,36v8h4v-8H4z M4,48v8h8v-4H8v-4H4z M4,24v8h4v-8H4z M36,8h-8v4h8V8z
                                      M48,8h-8v4h8V8z M60,28v-8h-4v8H60z M60,16V8h-8v4h4v4H60z M60,40v-8h-4v8H60z"
                />
                <path
                  className="st0"
                  d="M30,28H6c-1.104,0-2-0.896-2-2V10c0-1.104,0.896-2,2-2h24c1.104,0,2,0.896,2,2v16C32,27.104,31.104,28,30,28z
                                      M8,24h20V12H8V24z M22,28c-0.843,0-7.157,0-8,0c-1.104,0-2,0.896-2,2s0.896,2,2,2c0.843,0,7.157,0,8,0c1.104,0,2-0.896,2-2
                                  S23.104,28,22,28z M58,56H34c-1.104,0-2-0.896-2-2V38c0-1.104,0.896-2,2-2h24c1.104,0,2,0.896,2,2v16C60,55.104,59.104,56,58,56z
                                      M36,52h20V40H36V52z M50,56c-0.844,0-7.157,0-8,0c-1.104,0-2,0.896-2,2s0.896,2,2,2c0.843,0,7.156,0,8,0c1.104,0,2-0.896,2-2
                                  S51.104,56,50,56z"
                />
              </svg>
              <div>
                <h6 className="mb-0">P2P</h6>
                <small className="text-muted">Person to Person</small>
              </div>
            </a>
          </li>
          <li>
            <a className="m-link" href="battel.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st1"
                  d="M52.069,24.893c3.283,5.371,5.676,11.109,6.327,15.939c1.7-3.766,3.014-11.133-1.376-19.159
                                          c1.358-0.886,1.722-1.15,1.824-1.355c0.274-0.554-1.862-3.773-2.479-3.734c-0.226,0.015-0.609,0.248-1.955,1.154
                                          c-5.685-7.169-12.979-8.83-17.109-8.734c4.196,2.483,8.549,6.923,12.218,12.04c-3.22,2.163-8.002,5.344-15.179,10.016
                                          c-0.802,0.522-1.572,1.022-2.34,1.521c-0.768-0.499-1.539-0.999-2.34-1.521c-7.177-4.672-11.959-7.853-15.179-10.016
                                          c3.669-5.117,8.022-9.557,12.218-12.04c-4.131-0.096-11.425,1.565-17.11,8.734c-1.345-0.907-1.729-1.139-1.955-1.154
                                          c-0.617-0.039-2.754,3.181-2.479,3.734c0.103,0.205,0.466,0.469,1.825,1.355c-4.39,8.025-3.077,15.393-1.376,19.159
                                          c0.652-4.83,3.044-10.568,6.327-15.939c3.246,2.124,8.038,5.29,15.137,10.081c0.207,0.14,0.399,0.271,0.604,0.408
                                          C15.214,43.411,8.157,47.727,8.007,48.336c-0.16,0.711,2.051,4.043,2.764,4.166c0.644,0.11,7.943-5.148,21.229-14.186
                                          c13.285,9.037,20.585,14.296,21.229,14.186c0.713-0.123,2.924-3.455,2.764-4.166c-0.148-0.609-7.207-4.925-19.663-12.954
                                          c0.204-0.138,0.396-0.269,0.604-0.408C44.032,30.183,48.824,27.017,52.069,24.893z"
                />
                <path
                  className="st0"
                  d="M45.01,30c-0.353,0-0.701,0.103-1.01,0.234v-2.661C44,21.178,37.885,16,32,16c-5.878,0-12,5.178-12,11.573
                                          v2.66C19.692,30.102,19.344,30,18.992,30C17.998,30,17,30.818,17,34.081C17,37.348,18.161,40,20.1,40
                                          c0.055,0,0.097-0.024,0.149-0.031c0.642,4.521,2.502,7.143,5.143,9.307C26.98,50.576,29.168,52,32,52
                                          c2.829,0,5.016-1.424,6.61-2.725c2.636-2.164,4.498-4.786,5.141-9.307C43.804,39.975,43.846,40,43.902,40
                                          C45.841,40,47,37.348,47,34.081C47,30.818,46.006,30,45.01,30z M40,36.188c0,6.249-1.94,8.365-3.928,9.996
                                          C34.535,47.438,33.276,48,32,48c-1.281,0-2.538-0.562-4.074-1.819C25.94,44.553,24,42.44,24,36.188v-8.615
                                          C24,23.377,28.376,20,32,20c3.623,0,8,3.378,8,7.573V36.188z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Battel</h6>
                <small className="text-muted">Battel to Win</small>
              </div>
            </a>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#trade"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st1"
                  d="M32,18c0,7.733-6.267,14-14,14S4,25.733,4,18S10.267,4,18,4S32,10.267,32,18z M46,32c-7.732,0-14,6.268-14,14
                                  s6.268,14,14,14s14-6.268,14-14S53.732,32,46,32z"
                />
                <path
                  className="st0"
                  d="M51.979,50.234l-2-11c-0.1-0.549-0.495-0.998-1.028-1.164s-1.113-0.023-1.508,0.371l-2.38,2.379L23.791,19.549
                                  C23.923,19.054,24,18.537,24,18c0-3.313-2.687-6-6-6s-6,2.687-6,6s2.687,6,6,6c0.537,0,1.054-0.077,1.549-0.209L40.82,45.063
                                  l-2.378,2.379c-0.395,0.395-0.537,0.975-0.372,1.508c0.167,0.533,0.615,0.93,1.164,1.029l11,2
                                  C51.204,52.154,52.155,51.203,51.979,50.234z"
                />
              </svg>{" "}
              <div>
                <h6 className="mb-0">Trade</h6>
                <small className="text-muted">Easiest Way to Trade</small>
              </div>{" "}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="trade">
              <li>
                <a className="ms-link" href="convert-trade.html">
                  Convert
                </a>
              </li>
              <li>
                <a className="ms-link" href="margin-trade.html">
                  Margin
                </a>
              </li>
            </ul>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#finace"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
              >
                <path
                  className="st1"
                  d="M31.997,60C16.537,60,3.999,47.462,4,32.002C3.996,16.537,16.535,4,31.995,4C47.459,4,59.999,16.537,60,32
                                  S47.461,60,31.997,60z M53.999,32c-0.001-12.149-9.857-22-22.005-22C19.85,10,9.995,19.851,9.999,32.002
                                  C9.998,44.146,19.85,54,31.996,54C44.144,54,54,44.145,53.999,32L53.999,32z"
                />
                <path
                  className="st0"
                  d="M47.282,27.597c0.638-4.258-2.604-6.547-7.038-8.074l1.438-5.768l-3.51-0.875l-1.4,5.616
                                  c-0.924-0.23-1.871-0.447-2.813-0.662l1.409-5.653l-3.51-0.875l-1.439,5.767c-0.764-0.175-1.514-0.347-2.243-0.527l0.004-0.017
                                  l-4.842-1.21l-0.934,3.75c0,0,2.606,0.597,2.55,0.635c1.423,0.354,1.679,1.296,1.636,2.042l-3.939,15.801
                                  c-0.173,0.432-0.615,1.079-1.609,0.833c0.035,0.052-2.552-0.636-2.552-0.636l-1.743,4.019l4.569,1.14
                                  c0.85,0.213,1.683,0.435,2.503,0.646l-1.453,5.833l3.507,0.876l1.438-5.774c0.958,0.262,1.888,0.5,2.799,0.727l-1.434,5.745
                                  l3.51,0.876l1.454-5.824c4.734,0.896,10.564,0.461,12.384-4.739c1.517-4.325-1.217-7.469-3.226-8.515
                                  C45.095,32.224,46.821,30.714,47.282,27.597L47.282,27.597z M39.26,38.847c-1.084,4.36-8.425,2.004-10.806,1.413l1.929-7.729
                                  C32.761,33.124,40.395,34.3,39.26,38.847L39.26,38.847z M40.346,27.535c-0.989,3.966-7.1,1.951-9.083,1.458l1.748-7.011
                                  C34.994,22.476,41.377,23.397,40.346,27.535z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Finace</h6>
                <small className="text-muted">Investment Solution</small>
              </div>
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="finace">
              <li>
                <a className="ms-link" href="cryptoloan.html">
                  Crypto Loan
                </a>
              </li>
              <li>
                <a className="ms-link" href="cryptoonpay.html">
                  Cryptoon Pay
                </a>
              </li>
            </ul>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#app"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 38 38"
                width="24px"
                height="24px"
              >
                <ellipse
                  cx={19}
                  cy={17}
                  rx={13}
                  ry={9}
                  style={{ fill: "var(--primary-color)" }}
                  data-st="fill:var(--primary-color);"
                />
                <path
                  className="st0"
                  d="M38,17c0,8.3-8.5,15-19,15c-0.6,0-1.3,0-1.9-0.1L11,38v-9.6c0.6,0.3,1.3,0.5,2,0.7v4l3.3-3.3  c0.9,0.1,1.7,0.2,2.7,0.2c9.4,0,17-5.8,17-13c0-7.2-7.6-13-17-13C9.6,4,2,9.8,2,17c0,4.3,2.8,8.1,7,10.5v2.2C3.6,27.1,0,22.4,0,17  C0,8.7,8.5,2,19,2C29.5,2,38,8.7,38,17z M16,18h-4v2h4V18z M16,14h-4v2h4V14z M22,18h2c2.2,0,4-1.8,4-4s-1.8-4-4-4h-4v14h2V18z   M22,12h2c1.1,0,2,0.9,2,2s-0.9,2-2,2h-2V12z"
                />
              </svg>
              <div>
                <h6 className="mb-0">App</h6>
                <small className="text-muted">Chat Grop</small>
              </div>{" "}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="app">
              <li>
                <a className="ms-link" href="calendar.html">
                  Calandar
                </a>
              </li>
              <li>
                <a className="ms-link" href="chat.html">
                  {" "}
                  Chat App
                </a>
              </li>
            </ul>
          </li>
          <li className="divider mt-4 py-2 border-top text-uppercase">
            <small>Ready UI</small>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#form"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="20px"
                viewBox="0 0 32 32"
              >
                <path
                  d="M2,0v32h28V0H2z M28,30H4V2h24V30z"
                  style={{ fill: "var(--primary-color)" }}
                />
                <path
                  d="M19,8V4H6v10h20V8H19z M8,6h9v2H8V6z M24,12H8v-2h16V12z"
                  style={{ fill: "var(--svg-color)" }}
                />
                <path
                  d="M19,20v-4H6v10h20v-6H19z M8,18h9v2H8V18z M24,24H8v-2h16V24z"
                  style={{ fill: "var(--svg-color)" }}
                />
              </svg>
              <div>
                <h6 className="mb-0">Form Input</h6>
                <small className="text-muted">Form Example</small>
              </div>{" "}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="form">
              <li>
                <a className="ms-link" href="form-bootstrap.html">
                  Bootstrap Form
                </a>
              </li>
              <li>
                <a className="ms-link" href="form-custome.html">
                  Custome Form
                </a>
              </li>
              <li>
                <a className="ms-link" href="form-wizard.html">
                  Wizard Form
                </a>
              </li>
              <li>
                <a className="ms-link" href="form-inputmask.html">
                  Inputmask Form
                </a>
              </li>
            </ul>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#font"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 38 38"
                width="24px"
                height="24px"
              >
                <polygon
                  points="22,19.4 22,30 10,30 10,10 30,10 30,27.4"
                  style={{ fill: "var(--primary-color)" }}
                  data-st="fill:var(--chart-color4);"
                />
                <path
                  className="st0"
                  d="M28.3,37.5l-2.7-6.7L20,36.4V14.6l8,8V6H6v22h12v2H6c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h22c1.1,0,2,0.9,2,2  v18.6l5.4,5.4h-7.9l2.7,6.7L28.3,37.5z M22,19.4v12.2l3.6-3.6h5L22,19.4z"
                />
              </svg>
              <div>
                <h6 className="mb-0">Font Icon</h6>
                <small className="text-muted">Icon Example</small>
              </div>{" "}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="font">
              <li>
                <a className="ms-link" href="icon-iconfont.html">
                  Icofont Icon
                </a>
              </li>
              <li>
                <a className="ms-link" href="icon-bootstrap.html">
                  {" "}
                  Bootstrap Icon
                </a>
              </li>
              <li>
                <a className="ms-link" href="icon-fontawesome.html">
                  {" "}
                  Font-Awesome Icon
                </a>
              </li>
            </ul>
          </li>
          <li className="collapsed">
            <a
              className="m-link"
              data-bs-toggle="collapse"
              data-bs-target="#widget"
              href="#"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 32 32"
              >
                <path
                  d="M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,4.5c-6.62,0-12,5.38-12,12s5.38,12,12,12s12-5.38,12-12S22.12,4.5,15.5,4.5z M15.5,24.5  c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S19.91,24.5,15.5,24.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3  c1.65,0,3-1.35,3-3C18.5,14.85,17.15,13.5,15.5,13.5z"
                  style={{ fill: "var(--primary-color)" }}
                  data-st="fill:var(--chart-color4);"
                />
                <path
                  className="st2"
                  d="M15.5,8.5c-4.41,0-8,3.59-8,8s3.59,8,8,8s8-3.59,8-8S19.91,8.5,15.5,8.5z M15.5,19.5c-1.65,0-3-1.35-3-3  c0-1.65,1.35-3,3-3c1.65,0,3,1.35,3,3C18.5,18.15,17.15,19.5,15.5,19.5z"
                  style={{ fill: "#FFFFFF" }}
                />
                <path
                  className="st0"
                  d="M15.5,4C8.6,4,3,9.6,3,16.5C3,23.4,8.6,29,15.5,29C22.4,29,28,23.4,28,16.5C28,9.6,22.4,4,15.5,4z M15.5,28  C9.16,28,4,22.84,4,16.5S9.16,5,15.5,5S27,10.16,27,16.5S21.84,28,15.5,28z M15.5,8C10.81,8,7,11.81,7,16.5  c0,4.689,3.81,8.5,8.5,8.5c4.689,0,8.5-3.811,8.5-8.5C24,11.81,20.189,8,15.5,8z M15.5,24C11.36,24,8,20.641,8,16.5  C8,12.36,11.36,9,15.5,9c4.141,0,7.5,3.36,7.5,7.5C23,20.641,19.641,24,15.5,24z M15.5,13c-1.93,0-3.5,1.57-3.5,3.5  c0,1.93,1.57,3.5,3.5,3.5c1.93,0,3.5-1.57,3.5-3.5C19,14.57,17.43,13,15.5,13z M15.5,19c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5  s2.5,1.12,2.5,2.5S16.88,19,15.5,19z"
                  style={{ fill: "#4D5152" }}
                />
              </svg>
              <div>
                <h6 className="mb-0">Widget</h6>
                <small className="text-muted">Pages, Table, Chart</small>
              </div>{" "}
              <span className="arrow icofont-rounded-down ms-auto text-end fs-5" />
            </a>
            {/* Menu: Sub menu ul */}
            <ul className="sub-menu collapse" id="widget">
              <li>
                <a className="ms-link" href="widget-example.html">
                  Widget Example
                </a>
              </li>
              <li>
                <a className="ms-link" href="apexchart.html">
                  Apex Chart
                </a>
              </li>
              <li>
                <a className="ms-link" href="priceplan.html">
                  {" "}
                  Price Plan
                </a>
              </li>
              <li>
                <a className="ms-link" href="profile.html">
                  {" "}
                  Profile Page
                </a>
              </li>
              <li>
                <a className="ms-link" href="table.html">
                  {" "}
                  Tables
                </a>
              </li>
              <li>
                <a className="ms-link" href="todo-list.html">
                  {" "}
                  Todo-List
                </a>
              </li>
              <li>
                <a className="ms-link" href="support-ticket.html">
                  {" "}
                  Support-Ticket
                </a>
              </li>
            </ul>
          </li>
        </ul>
        {/* Menu: menu collepce btn */}
        <button
          type="button"
          className="btn btn-link sidebar-mini-btn text-muted"
        >
          <span>
            <i className="icofont-bubble-right" />
          </span>
        </button>
      </div>
    </div>
    {/* main body area */}
    <div className="main px-lg-4 px-md-4">
      {/* Body: Header */}
      <div className="header">
        <nav className="navbar py-4">
          <div className="container-xxl">
            {/* header rightbar icon */}
            <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
              <div className="d-flex">
                <a
                  className="nav-link text-primary collapsed"
                  href="wallet.html"
                  title="Wallet"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30px"
                    height="30px"
                    viewBox="0 0 64 64"
                  >
                    <path
                      className="st1"
                      d="M15,24c-3.86,0-7,2.691-7,6v20c0,3.309,3.14,6,7,6h41V32H15c-1.598,0-3-0.935-3-2s1.402-2,3-2h5.25
                                          c0,0,1-5,5.75-5s6,5,6,5h22v-4H15z"
                    />
                    <path
                      className="st0"
                      d="M42,4c-4.418,0-8,3.582-8,8s3.582,8,8,8c4.417,0,8-3.582,8-8S46.417,4,42,4z M42,16c-2.208,0-4-1.792-4-4
                                          s1.792-4,4-4s4,1.792,4,4S44.208,16,42,16z"
                    />
                    <path
                      className="st0"
                      d="M26,20c-4.418,0-8,3.582-8,8h4c0-2.208,1.792-4,4-4s4,1.792,4,4h4C34,23.582,30.418,20,26,20z"
                    />
                  </svg>
                </a>
              </div>
              <div className="dropdown notifications zindex-popover">
                <a
                  className="nav-link dropdown-toggle pulse"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="25px"
                    height="25px"
                    viewBox="0 0 38 38"
                  >
                    <path
                      d="M36,34v-2h-2.98c-0.598-0.363-1.081-3.663-1.4-5.847c-0.588-4.075-1.415-9.798-4.146-13.723  C26.584,12.154,25.599,12,24.5,12c-3.646,0-5.576,1.657-7.106,4.086C15.089,19.746,14,30.126,14,33c0,2.757,2.243,5,5,5  c2.414,0,4.435-1.721,4.898-4H36z"
                      style={{ fill: "var(--primary-color)" }}
                      data-st="fill:var(--chart-color4);"
                    />
                    <path
                      className="st0"
                      d="M33.02,32c-0.598-0.363-1.081-3.663-1.4-5.847c-0.851-5.899-2.199-15.254-9.101-17.604  C23.433,7.643,24,6.386,24,5c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,1.386,0.567,2.643,1.482,3.549  c-6.902,2.35-8.25,11.705-9.101,17.604C6.209,27.324,5.991,28.813,5.733,30h2.042c0.192-0.961,0.376-2.127,0.586-3.562  C9.36,19.501,10.73,10,19,10c8.27,0,9.64,9.501,10.641,16.442c0.386,2.636,0.682,4.394,1.108,5.558H2v2h12.101  c0.464,2.279,2.485,4,4.899,4c2.415,0,4.435-1.721,4.899-4H36v-2H33.02z M19,8c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3  S20.654,8,19,8z M19,36c-1.304,0-2.416-0.836-2.829-2h5.658C21.416,35.164,20.304,36,19,36z"
                    />
                  </svg>
                  <span className="pulse-ring" />
                </a>
                <div
                  id="NotificationsDiv"
                  className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0"
                >
                  <div className="card border-0 w380">
                    <div className="card-header border-0 p-3">
                      <h5 className="mb-0 font-weight-light d-flex justify-content-between">
                        <span>Notifications</span>
                        <span className="badge text-white">06</span>
                      </h5>
                    </div>
                    <div className="tab-content card-body">
                      <div className="tab-pane fade show active">
                        <ul className="list-unstyled list mb-0">
                          <li className="py-2 mb-1 border-bottom">
                            <a href="javascript:void(0);" className="d-flex">
                              <img
                                className="avatar rounded-circle"
                                src="assets/images/xs/avatar1.svg"
                                alt=""
                              />
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Chloe Walkerr
                                  </span>{" "}
                                  <small>2MIN</small>
                                </p>
                                <span className="">
                                  Added New Ico Coin 2021-08-25{" "}
                                  <span className="badge bg-success">Add</span>
                                </span>
                              </div>
                            </a>
                          </li>
                          <li className="py-2 mb-1 border-bottom">
                            <a href="javascript:void(0);" className="d-flex">
                              <div className="avatar rounded-circle no-thumbnail">
                                AH
                              </div>
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Alan Hill
                                  </span>{" "}
                                  <small>13MIN</small>
                                </p>
                                <span className="">Invoice generator </span>
                              </div>
                            </a>
                          </li>
                          <li className="py-2 mb-1 border-bottom">
                            <a href="javascript:void(0);" className="d-flex">
                              <img
                                className="avatar rounded-circle"
                                src="assets/images/xs/avatar3.svg"
                                alt=""
                              />
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Melanie Oliver
                                  </span>{" "}
                                  <small>1HR</small>
                                </p>
                                <span className="">User Document Verified</span>
                              </div>
                            </a>
                          </li>
                          <li className="py-2 mb-1 border-bottom">
                            <a href="javascript:void(0);" className="d-flex">
                              <img
                                className="avatar rounded-circle"
                                src="assets/images/xs/avatar5.svg"
                                alt=""
                              />
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Boris Hart
                                  </span>{" "}
                                  <small>13MIN</small>
                                </p>
                                <span className="">New Coin Listing</span>
                              </div>
                            </a>
                          </li>
                          <li className="py-2 mb-1 border-bottom">
                            <a href="javascript:void(0);" className="d-flex">
                              <img
                                className="avatar rounded-circle"
                                src="assets/images/xs/avatar6.svg"
                                alt=""
                              />
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Alan Lambert
                                  </span>{" "}
                                  <small>1HR</small>
                                </p>
                                <span className="">
                                  15 More Customer Supprot
                                </span>
                              </div>
                            </a>
                          </li>
                          <li className="py-2">
                            <a href="javascript:void(0);" className="d-flex">
                              <img
                                className="avatar rounded-circle"
                                src="assets/images/xs/avatar7.svg"
                                alt=""
                              />
                              <div className="flex-fill ms-2">
                                <p className="d-flex justify-content-between mb-0 ">
                                  <span className="font-weight-bold">
                                    Zoe Wright
                                  </span>{" "}
                                  <small className="">1DAY</small>
                                </p>
                                <span className="">
                                  Coin List Entry Updated
                                </span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <a
                      className="card-footer text-center border-top-0"
                      href="#"
                    >
                      {" "}
                      View all notifications
                    </a>
                  </div>
                </div>
              </div>
              <div className="dropdown zindex-popover">
                <a
                  className="nav-link dropdown-toggle pulse"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <img src="assets/images/flag/GB.png" alt="" />
                </a>
                <div className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0">
                  <div className="card border-0">
                    <ul className="list-unstyled py-2 px-3">
                      <li>
                        <a href="#" className="">
                          <img src="assets/images/flag/GB.png" alt="" /> English
                        </a>
                      </li>
                      <li>
                        <a href="#" className="">
                          <img src="assets/images/flag/DE.png" alt="" /> German
                        </a>
                      </li>
                      <li>
                        <a href="#" className="">
                          <img src="assets/images/flag/FR.png" alt="" /> French
                        </a>
                      </li>
                      <li>
                        <a href="#" className="">
                          <img src="assets/images/flag/IT.png" alt="" /> Italian
                        </a>
                      </li>
                      <li>
                        <a href="#" className="">
                          <img src="assets/images/flag/RU.png" alt="" /> Russian
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                <a
                  className="nav-link dropdown-toggle pulse p-0"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                >
                  <img
                    className="avatar lg rounded-circle img-thumbnail"
                    src="assets/images/profile_av.svg"
                    alt="profile"
                  />
                </a>
                <div className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                  <div className="card border-0 w280">
                    <div className="card-body pb-0">
                      <div className="d-flex py-1">
                        <img
                          className="avatar rounded-circle"
                          src="assets/images/profile_av.svg"
                          alt="profile"
                        />
                        <div className="flex-fill ms-3">
                          <p className="mb-0">
                            <span className="font-weight-bold">John Quinn</span>
                          </p>
                          <small className="">Johnquinn@gmail.com</small>
                        </div>
                      </div>
                      <div>
                        <hr className="dropdown-divider border-dark" />
                      </div>
                    </div>
                    <div className="list-group m-2 ">
                      <a
                        href="profile.html"
                        className="list-group-item list-group-item-action border-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 38 38"
                          className="me-3"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M36.15,38H1.85l0.16-1.14c0.92-6.471,3.33-7.46,6.65-8.83c0.43-0.17,0.87-0.36,1.34-0.561  c0.19-0.08,0.38-0.17,0.58-0.26c1.32-0.61,2.14-1.05,2.64-1.45c0.18,0.48,0.47,1.13,0.93,1.78C15.03,28.78,16.53,30,19,30  c2.47,0,3.97-1.22,4.85-2.46c0.46-0.65,0.75-1.3,0.931-1.78c0.5,0.4,1.319,0.84,2.64,1.45c0.2,0.09,0.39,0.17,0.58,0.26  c0.47,0.2,0.91,0.391,1.34,0.561c3.32,1.37,5.73,2.359,6.65,8.83L36.15,38z M20,13v4h-2v-4H20z"
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M21.67,17.34C21.22,18.27,20.29,19,19,19s-2.22-0.73-2.67-1.66l-1.79,0.891C15.31,19.78,16.88,21,19,21  s3.69-1.22,4.46-2.77L21.67,17.34z M15,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.49,1.65,1.1,1.65s1.1-0.38,1.1-1.65S15.61,10.85,15,10.85  z M23,10.85c-0.61,0-1.1,0.38-1.1,1.65s0.489,1.65,1.1,1.65s1.1-0.38,1.1-1.65S23.61,10.85,23,10.85z M35.99,36.86  c-0.92-6.471-3.33-7.46-6.65-8.83c-0.43-0.17-0.87-0.36-1.34-0.561c-0.19-0.09-0.38-0.17-0.58-0.26c-1.32-0.61-2.14-1.05-2.64-1.45  c-0.521-0.42-0.7-0.8-0.761-1.29C26.55,22.74,28,19.8,28,17V4.56l-1.18,0.21C26.1,4.91,25.58,5,25.05,5  c-1.439,0-2.37-0.24-3.35-0.49C20.71,4.26,19.68,4,18.21,4c-1.54,0-2.94,0.69-3.83,1.9l1.61,1.18C16.5,6.39,17.31,6,18.21,6  c1.22,0,2.08,0.22,3,0.45C22.22,6.71,23.36,7,25.05,7c0.32,0,0.63-0.02,0.95-0.06V17c0,3.44-2.62,7-7,7s-7-3.56-7-7V6.29  C12.23,5.59,13.61,2,18.21,2c1.61,0,2.76,0.28,3.88,0.55C23.06,2.78,23.98,3,25.05,3C26.12,3,27.19,2.74,28,2.47V0.34  C27.34,0.61,26.17,1,25.05,1c-0.83,0-1.6-0.18-2.49-0.4C21.38,0.32,20.05,0,18.21,0c-5.24,0-7.64,3.86-8.18,5.89L10,17  c0,2.8,1.45,5.74,3.98,7.47c-0.06,0.49-0.24,0.87-0.76,1.29c-0.5,0.4-1.32,0.84-2.64,1.45c-0.2,0.09-0.39,0.18-0.58,0.26  c-0.47,0.2-0.91,0.391-1.34,0.561c-3.32,1.37-5.73,2.359-6.65,8.83L1.85,38h34.3L35.99,36.86z M4.18,36c0.81-4.3,2.28-4.9,5.24-6.12  c0.62-0.25,1.29-0.53,2-0.86c1.09-0.5,2.01-0.949,2.73-1.479c0.8-0.56,1.36-1.22,1.64-2.12C16.76,25.78,17.83,26,19,26  s2.24-0.22,3.21-0.58c0.28,0.9,0.84,1.561,1.64,2.12c0.721,0.53,1.641,0.979,2.73,1.479c0.71,0.33,1.38,0.61,2,0.86  c2.96,1.22,4.43,1.83,5.24,6.12H4.18z"
                          />
                        </svg>
                        Profile Page
                      </a>
                      <a
                        href="security.html"
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 32 32"
                          className="me-3"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M15.5,27.482C5.677,24.8,4.625,10.371,4.513,7.497C11.326,7.402,14.5,5.443,15.5,4.661  c0.999,0.782,4.174,2.742,10.986,2.836C26.375,10.371,25.323,24.8,15.5,27.482z"
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st2"
                            d="M14.13,21.5c-0.801,0-1.553-0.311-2.116-0.873c-0.57-0.57-0.883-1.327-0.881-2.132  c0.001-0.8,0.314-1.55,0.879-2.11c0.555-0.563,1.297-0.876,2.093-0.885c0.131-0.001,0.256-0.054,0.348-0.146l4.63-4.63  c0.388-0.38,0.879-0.583,1.416-0.583s1.028,0.203,1.42,0.587c0.373,0.373,0.58,0.875,0.58,1.413c0,0.531-0.207,1.03-0.584,1.406  l-4.64,4.641c-0.094,0.095-0.146,0.222-0.146,0.354c0,0.782-0.311,1.522-0.873,2.087C15.693,21.189,14.938,21.5,14.13,21.5z"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M15.5,4c0,0-2.875,3-11.5,3c0,0,0,18,11.5,21C27,25,27,7,27,7C18.375,7,15.5,4,15.5,4z M15.5,26.984  C6.567,24.43,5.217,11.608,5.015,7.965C11.052,7.797,14.213,6.15,15.5,5.251c1.287,0.899,4.448,2.545,10.484,2.713  C25.782,11.608,24.434,24.43,15.5,26.984z M22.27,10.37c-0.479-0.47-1.1-0.73-1.77-0.73s-1.29,0.261-1.77,0.73L14.1,15  c-0.92,0.01-1.79,0.37-2.44,1.03c-1.37,1.358-1.37,3.579,0,4.95c0.66,0.66,1.54,1.02,2.47,1.02c0.94,0,1.82-0.359,2.479-1.02  c0.66-0.66,1.021-1.53,1.021-2.44l4.64-4.64C22.74,13.43,23,12.81,23,12.14C23,11.47,22.74,10.84,22.27,10.37z M21.561,13.2  l-4.949,4.95c0.1,0.75-0.13,1.539-0.71,2.119C15.41,20.76,14.77,21,14.13,21c-0.64,0-1.28-0.24-1.76-0.73  c-0.98-0.979-0.98-2.56,0-3.539c0.49-0.489,1.12-0.729,1.76-0.729c0.12,0,0.24,0.01,0.36,0.03l4.949-4.95  c0.291-0.3,0.681-0.44,1.061-0.44s0.77,0.141,1.061,0.44C22.15,11.66,22.15,12.61,21.561,13.2z M19.79,12.14l0.71,0.7l-5.02,5.021  c0.27,0.56,0.18,1.238-0.29,1.699c-0.58,0.59-1.53,0.59-2.12,0c-0.58-0.58-0.58-1.529,0-2.119c0.47-0.461,1.16-0.562,1.71-0.291  L19.79,12.14z M16,11H9v-1h7V11z M14,13H9v-1h5V13z"
                          />
                        </svg>
                        Security
                      </a>
                      <a
                        href="identification.html"
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          className="me-3"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M4,12c0-4.418,3.582-8,8-8s8,3.582,8,8s-3.582,8-8,8S4,16.418,4,12z"
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ opacity: "0.7" }}
                            d="M12,17.25c-1.689,0-3.265-0.909-4.113-2.372l1.298-0.752C9.766,15.128,10.844,15.75,12,15.75  c1.162,0,2.244-0.628,2.823-1.639l1.301,0.746C15.279,16.333,13.699,17.25,12,17.25z M8.5,12c0.552,0,1-0.672,1-1.5S9.052,9,8.5,9  s-1,0.672-1,1.5S7.948,12,8.5,12z M15.5,12c0.552,0,1-0.672,1-1.5S16.052,9,15.5,9c-0.552,0-1,0.672-1,1.5S14.948,12,15.5,12z"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            id="shock_x5F_color"
                            d="M4,6H2V2h4v2H4V6z M22,2h-4v2h2v2h2V2z M6,20H4v-2H2v4h4V20z M5,11H2v2h3V11z   M22,11h-3v2h3V11z M13,19h-2v3h2V19z M13,2h-2v3h2V2z M22,18h-2v2h-2v2h4V18z"
                          />
                        </svg>
                        Identification
                      </a>
                      <a
                        href="referral.html"
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 32 32"
                          className="me-3"
                        >
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M15,5v12c0,0,0,2-2,2H5c0,0-2,0-2-2L3.01,4.89C3.04,4.46,3.29,3,5,3h8C13,3,15,3,15,5z   M27,13h-8c-1.71,0-1.96,1.46-1.99,1.89L17,27c0,2,2,2,2,2h8c2,0,2-2,2-2V15C29,13,27,13,27,13z"
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M13,3H5C3.29,3,3.04,4.46,3.01,4.89L3,17c0,2,2,2,2,2h8c2,0,2-2,2-2V5C15,3,13,3,13,3z M9,12.5  c-1.93,0-3.5-1.57-3.5-3.5S7.07,5.5,9,5.5s3.5,1.57,3.5,3.5S10.93,12.5,9,12.5z"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M14,16.12l0.99,0.99C14.96,17.54,14.71,19,13,19H5c0,0-2,0-2-2V5c0,0,0-2,2-2h8c0,0,2,0,2,2v7.88l-1-1V5  c0-0.8-0.55-0.99-1.01-1H5C4.19,4,4.01,4.55,4,5.01V17c0,0.81,0.55,0.99,1.01,1H13c0.81,0,0.99-0.55,1-1.01V16.12z M27,13h-8  c-1.71,0-1.96,1.46-1.99,1.89L18,15.88v-0.87c0.01-0.46,0.19-1.01,1-1.01h7.99c0.46,0.01,1.01,0.2,1.01,1v11.99  C27.99,27.45,27.81,28,27,28h-7.99C18.55,27.99,18,27.81,18,27v-6.88l-1-1V27c0,2,2,2,2,2h8c2,0,2-2,2-2V15C29,13,27,13,27,13z   M23,20h1v4h-4v-1h2.3L11.45,12.15C10.77,12.69,9.92,13,9,13c-2.21,0-4-1.79-4-4s1.79-4,4-4s4,1.79,4,4c0,0.92-0.31,1.77-0.85,2.45  L23,22.3V20z M12,9c0-1.65-1.35-3-3-3S6,7.35,6,9s1.35,3,3,3c0.64,0,1.25-0.21,1.74-0.56L9.15,9.85l0.7-0.7l1.59,1.59  C11.79,10.25,12,9.64,12,9z M22,26h4v-4h-1v3h-3V26z"
                          />
                        </svg>
                        Referral
                      </a>
                      <a
                        href="coupons.html"
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 38 38"
                          className="me-3"
                        >
                          <rect
                            xmlns="http://www.w3.org/2000/svg"
                            x={6}
                            y={10}
                            width={26}
                            height={18}
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M12,18H8v2h4V18z M20,26h-2v-2.056c-1.14-0.138-1.996-0.532-2.703-1.231l1.406-1.422  C17.212,21.795,17.878,22,19,22c0.02,0,2-0.012,2-1c0-0.438-0.143-0.649-0.545-0.809C19.968,19.999,19.329,20,19,20  c-0.421,0.007-1.349,0.001-2.19-0.332C15.643,19.207,15,18.26,15,17c0-1.723,1.388-2.654,3-2.919V12h2v2.056  c1.14,0.137,1.996,0.532,2.703,1.231l-1.406,1.422C20.787,16.206,20.122,16,19,16c-0.02,0-2,0.011-2,1  c0,0.438,0.143,0.649,0.545,0.809C18.032,18.001,18.688,18.002,19,18c0.44,0.012,1.349,0,2.19,0.332C22.357,18.793,23,19.74,23,21  c0,1.723-1.388,2.654-3,2.92V26z M12,30h6v2h-6V30z M20,30h6v2h-6V30z M28,30h6v2h-6V30z M4,30h6v2H4V30z M12,6h6v2h-6V6z M20,6h6v2  h-6V6z M28,6h6v2h-6V6z M4,6h6v2H4V6z M2,22v-6h2v6H2z M2,14V8h2v6H2z M2,30v-6h2v6H2z M34,22v-6h2v6H34z M34,14V8h2v6H34z M34,30  v-6h2v6H34z"
                          />
                        </svg>
                        Reward Center
                      </a>
                      <a
                        href="ui-elements/auth-signin.html"
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          className="me-3"
                        >
                          <rect
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            width={24}
                            height={24}
                            style={{ fill: "none" }}
                            fill="none"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            d="M20,4c0-1.104-0.896-2-2-2H6C4.896,2,4,2.896,4,4v16c0,1.104,0.896,2,2,2h12  c1.104,0,2-0.896,2-2V4z"
                            style={{ fill: "var(--primary-color)" }}
                            data-st="fill:var(--chart-color4);"
                          />
                          <path
                            xmlns="http://www.w3.org/2000/svg"
                            className="st0"
                            d="M15,6.81v2.56c0.62,0.7,1,1.62,1,2.63c0,2.21-1.79,4-4,4s-4-1.79-4-4c0-1.01,0.38-1.93,1-2.63V6.81  C7.21,7.84,6,9.78,6,12c0,3.31,2.69,6,6,6c3.31,0,6-2.69,6-6C18,9.78,16.79,7.84,15,6.81z M13,6.09C12.68,6.03,12.34,6,12,6  s-0.68,0.03-1,0.09V12h2V6.09z"
                          />
                        </svg>
                        Signout
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting ms-2">
                <a
                 onClick={()=>{
                  if(sidb === "open"){
                    setsidb("")
                  }else{
                    setsidb("open")
                  }
                 }}
                >
                 menu
                </a>
              </div>
            </div>
            {/* menu toggler */}
            <button
              className="navbar-toggler p-0 border-0 menu-toggle order-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mainHeader"
            >
              <span className="fa fa-bars" />
            </button>
            {/* main menu Search*/}
            <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 d-flex align-items-center">
              <a
                className="menu-toggle-option me-3 text-primary d-flex align-items-center"
                href="#"
                title="Menu Option"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  fill="var(--chart-color1)"
                  className="bi bi-ui-checks-grid"
                  viewBox="0 0 16 16"
                >
                  <path
                    style={{ fill: "var(--chart-color1)" }}
                    d="M2 10h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm9-9h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zm0 9a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-3zm0-10a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-3zM2 9a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H2zm7 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3zM0 2a2 2 0 0 1 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5.354.854a.5.5 0 1 0-.708-.708L3 3.793l-.646-.647a.5.5 0 1 0-.708.708l1 1a.5.5 0 0 0 .708 0l2-2z"
                  />
                </svg>
              </a>
              <div className="main-search border-start px-3 flex-fill">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter your search key word"
                />
                <div className="card border-0 shadow rounded-3 search-result slidedown">
                  <div className="card-body text-start">
                    <small className="dropdown-header">Recent searches</small>
                    <div className="dropdown-item bg-transparent text-wrap my-2">
                      <a className="badge bg-primary" href="#">
                        Pixelwibes <i className="fa fa-search ms-1" />
                      </a>
                      <a className="badge bg-primary" href="#">
                        Theme <i className="fa fa-search ms-1" />
                      </a>
                      <a className="badge bg-primary" href="#">
                        Color Full <i className="fa fa-search ms-1" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* topmain menu */}
        <div className="container-xxl position-relative">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow menu slidedown position-absolute zindex-modal">
                <div className="card-body p-3">
                  <div className="row g-3">
                    <div className="d-none d-lg-block col-lg-2 text-start">
                      <h6 className="px-2 text-primary mb-0">Download App</h6>
                      <img
                        src="assets/images/qr-code.png"
                        alt="Download App"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-10">
                      <ul className="menu-grid list-unstyled row row-cols-xl-3 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 g-4 mb-0 mt-lg-3">
                        <li className="col">
                          <a href="help.html" className="d-flex color-700">
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 38 38"
                              >
                                <circle
                                  xmlns="http://www.w3.org/2000/svg"
                                  cx={19}
                                  cy={19}
                                  r={11}
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M19,2c9.374,0,17,7.626,17,17c0,8.304-6.011,15.3-14,16.725v-2.025C28.847,32.309,34,26.257,34,19  c0-8.284-6.716-15-15-15S4,10.716,4,19s6.716,15,15,15c0.338,0,0.668-0.028,1-0.05V36h-1C9.626,36,2,28.374,2,19S9.626,2,19,2z   M20,23.417c0-2.067,0.879-2.99,1.896-4.06C22.882,18.322,24,17.148,24,15c0-2.757-2.243-5-5-5s-5,2.243-5,5h2c0-1.654,1.346-3,3-3  s3,1.346,3,3c0,1.348-0.651,2.032-1.552,2.979C19.357,19.124,18,20.55,18,23.417V26h2V23.417z M20,28h-2v2h2V28z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Help</p>
                              <small className="text-muted">
                                How May I Help You?
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a
                            href="ui-elements/ui-alerts.html"
                            className="d-flex color-700"
                          >
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st2"
                                  width={24}
                                  height={24}
                                  style={{ fill: "none" }}
                                  fill="none"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  d="M13,1.07V9h7C20,4.92,16.95,1.56,13,1.07z M4,15c0,4.42,3.58,8,8,8s8-3.58,8-8v-4H4V15z   M11,1.07C7.05,1.56,4,4.92,4,9h7V1.07z"
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M13,1.07V9h7C20,4.92,16.95,1.56,13,1.07z M11,1.07C7.05,1.56,4,4.92,4,9h7V1.07z"
                                  style={{ opacity: "0.2", fill: "#FFFFFF" }}
                                  fill="rgb(255, 255, 255)"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M6,15c-1.66,0-2.491,0.82-2.941,2.418C2.628,18.939,2.625,19.625,1,20.407C1.92,21.38,3.49,22,5,22  c2.21,0,4-1.563,4-3.719C9,16.389,7.66,15,6,15z M21.49,5C20,7,17.96,10.04,16,12c-1.48,1.48-5.48,3.93-5.48,3.93L8.07,13.48  c0,0,2.45-4,3.93-5.48c1.96-1.96,5-4,7-5.48c0.78-0.58,1.8-0.69,2.49,0C22.17,3.2,22.06,4.22,21.49,5z"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M16,12c-1.479,1.48-5.477,3.927-5.477,3.927l-2.449-2.45c0,0,2.445-3.998,3.926-5.477L16,12z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">UI Components</p>
                              <small className="text-muted">
                                Bootstrap Components
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a href="invoices.html" className="d-flex color-700">
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 38 38"
                              >
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  d="M22,6h2c0.875,0,1.513,0.657,2,1.31V10h4.501L32,12v24H22V6z"
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M10,14v18h18V14h-6v2h4v14h-6v-2.059c1.989-0.236,3-1.22,3-2.941c0-0.805-0.27-1.5-0.78-2.01  C21.226,21.998,19.654,22.003,19,22c-0.352-0.007-1.398,0.002-1.806-0.405C17.111,21.512,17,21.359,17,21c0-0.469,0-1,2-1  c1.122,0,1.788,0.205,2.297,0.709l1.406-1.422c-0.704-0.697-1.568-1.083-2.703-1.222V14H10z M18,18.059  c-1.988,0.236-3,1.221-3,2.941c0,0.805,0.271,1.5,0.781,2.01c0.994,0.992,2.543,0.989,3.22,0.99  c0.343-0.008,1.397-0.002,1.805,0.405C20.89,24.488,21,24.641,21,25c0,0.469,0,1-2,1c-1.121,0-1.787-0.205-2.297-0.709l-1.406,1.422  c0.705,0.697,1.568,1.083,2.703,1.222V30h-6V16h6V18.059z M30,14v20H8V4h15c0.46,0,1,0.26,1,1v3H12v2h12v2h7.99  c0,0-6.08-8.17-6.62-8.87C24.83,2.44,23.99,2,23,2H6v34h26V14H30z M26,7.31L28.01,10H26V7.31z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Invoices</p>
                              <small className="text-muted">
                                Simple, List, Email Invoice{" "}
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a
                            href="salaryslip.html"
                            className="d-flex color-700"
                          >
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  d="M20,20c0,1.104-0.896,2-2,2H6c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h8l6,6V20z"
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M16,8c-1.1,0-1.99-0.9-1.99-2L14,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h1v-1.25C7,19.09,10.33,18,12,18  s5,1.09,5,2.75V22h1c1.1,0,2-0.9,2-2V8H16z M12,17c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3S13.66,17,12,17z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">SalarySlip</p>
                              <small className="text-muted">
                                Simple SalarySlip
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a href="expenses.html" className="d-flex color-700">
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 38 38"
                              >
                                <circle
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="stshockcolor"
                                  cx={19}
                                  cy={19}
                                  r={11}
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M36,19c0,8.35-6.05,15.31-14,16.73V33.7c6.84-1.391,12-7.46,12-14.7c0-8.27-6.73-15-15-15C10.73,4,4,10.73,4,19  c0,8.27,6.73,15,15,15c0.34,0,0.67-0.01,1-0.04v2.01C19.67,35.99,19.34,36,19,36C9.63,36,2,28.37,2,19S9.63,2,19,2S36,9.63,36,19z   M19.257,17.588C15.516,16.591,15,15.487,15,14.443c0-1.43,1.4-2.185,3-2.383v3.008c0.412,0.175,0.973,0.375,1.772,0.587  c0.08,0.021,0.149,0.046,0.228,0.068v-3.596c1.726,0.359,3,1.504,3,2.872h2c0-2.442-2.159-4.478-5-4.912V8h-2v2.059  c-2.979,0.285-5,1.998-5,4.384c0,3.126,2.903,4.321,5.743,5.078C20.686,20.037,23,21.074,23,23.085c0,1.611-1.107,2.647-3,2.868  v-3.839c-0.468-0.244-1.069-0.475-1.771-0.661c-0.07-0.019-0.152-0.041-0.229-0.062v4.456c-1.692-0.393-3-1.549-3-2.848h-2  c0,2.424,2.153,4.448,5,4.903V30h2v-2.036c3.445-0.305,5-2.601,5-4.879C25,21.273,24.004,18.849,19.257,17.588z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Expenses</p>
                              <small className="text-muted">
                                Expenses List
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a
                            href="ui-elements/stater-page.html"
                            className="d-flex color-700"
                          >
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  style={{ fill: "none" }}
                                  fill="none"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  d="M20,20c0,1.104-0.896,2-2,2H6c-1.104,0-2-0.896-2-2V4c0-1.104,0.896-2,2-2h8l6,6V20z"
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M11,13h2v2h-2V13z M20,8v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h8l0.01,4  c0,1.1,0.891,2,1.99,2H20z M17,11h-2V9h-2v2h-2V9H9v2H7v2h2v2H7v2h2v2h2v-2h2v2h2v-2h2v-2h-2v-2h2V11z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Stater page</p>
                              <small className="text-muted">
                                Start working with
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a
                            href="ui-elements/documentation.html"
                            className="d-flex color-700"
                          >
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  d="M25.5,9.78V28.5c0,0.56-0.44,1-1,1h-17c-0.56,0-1-0.44-1-1v-25c0-0.55,0.45-1,1-1h10.72  L25.5,9.78z"
                                  style={{ fill: "var(--primary-color)" }}
                                  data-st="fill:var(--chart-color4);"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M19.5,9.5c-0.561,0-1-0.439-1-1V2.793L25.207,9.5H19.5z"
                                />
                                <path
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  d="M19,16c0-2.65,0.54-4,2-4c0.98,0,1.7,0.63,2,1.83l-0.89,0.6C21.92,13.49,21.43,13,21,13c-0.62,0-1,1.01-1,3  s0.38,3,1,3c0.43,0,0.92-0.49,1.11-1.43l0.89,0.6c-0.3,1.2-1.02,1.83-2,1.83C19.54,20,19,18.65,19,16z M18,16c0,3-0.9,4-2,4  c-1.1,0-2-1-2-4s0.9-4,2-4C17.1,12,18,13,18,16z M17,16c0-0.7,0-3-1-3s-1,2.3-1,3s0,3,1,3S17,16.7,17,16z M13,16.04  c0,2.88-0.8,3.96-2.4,3.96C9.8,20,9,20,9,20v-8c0,0,0.8,0,1.6,0C12.2,12,13,13.15,13,16.04z M12,16.03c0-2.17-0.52-3.03-1.33-3.03  c-0.4,0-0.67,0-0.67,0v6c0,0,0.27,0,0.67,0C11.48,19,12,18.2,12,16.03z M26,10v18.5c0,0.828-0.672,1.5-1.5,1.5h-17  C6.672,30,6,29.328,6,28.5v-25C6,2.672,6.672,2,7.5,2H18c0.621,0,0.646,0.232,1,0.586L25.414,9C25.768,9.354,26,9.368,26,10z   M19,8.5C19,8.776,19.224,9,19.5,9c0,0,2.639,0,4.5,0l-5-5V8.5z M25,10h-5.5C18.672,10,18,9.328,18,8.5V3c0,0-9.5,0-10.5,0  C7.225,3,7,3.224,7,3.5v25C7,28.776,7.225,29,7.5,29h17c0.275,0,0.5-0.224,0.5-0.5C25,28,25,10,25,10z"
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Documentation</p>
                              <small className="text-muted">
                                How to Install
                              </small>
                            </div>
                          </a>
                        </li>
                        <li className="col">
                          <a
                            href="ui-elements/changelog.html"
                            className="d-flex color-700"
                          >
                            <div className="avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                              >
                                <rect
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  fill="none"
                                />
                                <polygon
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st0"
                                  points="22,6 22,12 20,12 20,9.42 13,16.41 8.95,12.36 2.65,17.76 1.35,16.24 9.05,9.64   13,13.59 18.58,8 16,8 16,6 "
                                />
                                <polygon
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="st1"
                                  points="11.91,12.5 10.58,13.99 8.95,12.36 2.65,17.76 1.35,16.24 9.05,9.64 "
                                />
                              </svg>
                            </div>
                            <div className="flex-fill text-truncate">
                              <p className="h6 mb-0">Changelog</p>
                              <small className="text-muted">
                                Changelog Update
                              </small>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Body: Body */}
      <div className="body d-flex py-3">
        <div className="container-xxl">
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                      <div className="d-flex">
                        <img
                          className="avatar rounded-circle"
                          src="assets/images/profile_av.svg"
                          alt="profile"
                        />
                        <div className="flex-fill ms-3">
                          <p className="mb-0">
                            <span className="font-weight-bold">John Quinn</span>
                          </p>
                          <small className="">Johnquinn@gmail.com</small>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-3">
                      <div className="d-flex flex-column">
                        <span className="text-muted mb-1">
                          User ID:164647708
                        </span>
                        <span className="small text-muted flex-fill text-truncate">
                          Last login time 2021-09-29 10:56:22
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-2">
                      <div className="d-flex-inline">
                        <span className="badge bg-careys-pink mb-1">
                          Personal
                        </span>
                        <span className="small text-muted d-flex align-items-center">
                          <i className="icofont-diamond px-1 fs-5 color-lightyellow " />{" "}
                          VIP
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4">
                      <a
                        href="referral.html"
                        title="invite"
                        className="btn btn-primary text-dark mb-1"
                      >
                        40% commission:Invite friends now!
                      </a>
                      <a href="#" title="invite" className="d-block">
                        <i className="icofont-twitter px-1 fs-6 color-lightblue" />
                        not linked
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Row End */}
          <div className="row g-3 mb-3 row-cols-1 row-cols-md-2 row-cols-lg-4">
            <div className="col">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="flex-fill text-truncate">
                    <span className="text-muted small text-uppercase">
                      BNB/BUSD
                    </span>
                    <div className="d-flex flex-column">
                      <div className="price-block">
                        <span className="fs-6 fw-bold color-price-up">418</span>
                        <span className="small text-muted px-2">$418</span>
                      </div>
                      <div className="price-report">
                        <span className="small text-danger">
                          - 1.28% <i className="fa fa-level-down" />
                        </span>
                        <span className="small text-muted px-2">
                          Volume:109,267,865.92 BUSD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="apexspark1" />
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="flex-fill text-truncate">
                    <span className="text-muted small text-uppercase">
                      ETH/USDT
                    </span>
                    <div className="d-flex flex-column">
                      <div className="price-block">
                        <span className="fs-6 fw-bold color-price-down">
                          3499
                        </span>
                        <span className="small text-muted px-2">$3500</span>
                      </div>
                      <div className="price-report">
                        <span className="small text-danger">
                          - 1.79% <i className="fa fa-level-down" />
                        </span>
                        <span className="small text-muted px-2">
                          Volume:541,545,011.76 USDT
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="apexspark2" />
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="flex-fill text-truncate">
                    <span className="text-muted small text-uppercase">
                      DOT/BUSD
                    </span>
                    <div className="d-flex flex-column">
                      <div className="price-block">
                        <span className="fs-6 fw-bold">35.00</span>
                        <span className="small text-muted px-2">$35</span>
                      </div>
                      <div className="price-report">
                        <span className="small text-success">
                          + 3.78% <i className="fa fa-level-up" />
                        </span>
                        <span className="small text-muted px-2">
                          Volume:63,324,607.43 BUSD BUSD
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="apexspark3" />
              </div>
            </div>
            <div className="col">
              <div className="card">
                <div className="card-body d-flex align-items-center">
                  <div className="flex-fill text-truncate">
                    <span className="text-muted small text-uppercase">
                      GRT/USDT
                    </span>
                    <div className="d-flex flex-column">
                      <div className="price-block">
                        <span className="fs-6 fw-bold color-price-up">
                          0.8413
                        </span>
                        <span className="small text-muted px-2">$1</span>
                      </div>
                      <div className="price-report">
                        <span className="small text-danger">
                          - 1.11% <i className="fa fa-level-down" />
                        </span>
                        <span className="small text-muted px-2">
                          Volume:28,538,521.44 USDT
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="apexspark4" />
              </div>
            </div>
          </div>
          {/* Row End */}
          <div className="row g-3 mb-3 row-deck">
            <div className="col-xl-12 col-xxl-7">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                  <h6 className="mb-0 fw-bold">Balance Details</h6>
                  <ul
                    className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#Spot"
                        role="tab"
                      >
                        Spot
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#P2P"
                        role="tab"
                      >
                        P2P
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Margin"
                        role="tab"
                      >
                        Margin
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Future"
                        role="tab"
                      >
                        Future
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Earn"
                        role="tab"
                      >
                        Earn
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="Spot">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Account balance:</div>
                          <h3>0.18005388 BTC</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">
                            Buy this month
                          </div>
                          <h5>3.0675432 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Sell this month
                          </div>
                          <h5>2.0345618 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Estimated Value:
                          </div>
                          <h5>$22000.29</h5>
                        </div>
                        <div className="col-lg-6">
                          <div id="apex-simple-donut" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="P2P">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Account balance:</div>
                          <h3>0.00005388 BTC</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">
                            Buy this month
                          </div>
                          <h5>0.00005388 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Sell this month
                          </div>
                          <h5>2.0345618 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Estimated Value:
                          </div>
                          <h5>$2000.29</h5>
                        </div>
                        <div className="col-lg-6">
                          <div id="apex-simple-donutp2p" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="Margin">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Total balance:</div>
                          <h3>0.00095000 BTC≈$3570</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">
                            Total Debt:
                          </div>
                          <h5>0.00005388 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Account Equity:
                          </div>
                          <h5>2.0345618 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Profit &amp; Loss:
                          </div>
                          <h5>0.95 BTC(1.6.00%) $25 (8.00%)</h5>
                        </div>
                        <div className="col-lg-6">
                          <div id="apex-circle-chart-multiplemargin" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="Future">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Total Margin Balance:</div>
                          <h3>0.00095000 BTC≈$3570</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">
                            Total Wallet Balance:
                          </div>
                          <h5>0.00005388 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Total Unrealized PNL:
                          </div>
                          <h5>2.0345618 BTC</h5>
                        </div>
                        <div className="col-lg-6">
                          <div id="apex-circle-gradientfuture" />
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="Earn">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Total Margin Balance:</div>
                          <h3>0.00095000 BTC≈$3570</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">
                            Locked:
                          </div>
                          <h5>0.00000388 BTC</h5>
                          <div className="mt-3 text-uppercase text-muted small">
                            Flexible:
                          </div>
                          <h5>0.0000018 BTC</h5>
                        </div>
                        <div className="col-lg-6">
                          <div id="apex-circle-chartearn" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xxl-5">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center">
                  <h6 className="mb-0 fw-bold">
                    Increase your account security
                  </h6>
                  <a
                    href="security.html"
                    title="security"
                    className="d-inline-flex"
                  >
                    <i className="icofont-caret-right fs-5" />
                  </a>
                </div>
                <div className="card-body">
                  <div className="row row-cols-2 g-0">
                    <div className="col">
                      <div className="security border-bottom border-end">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-green mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Enable 2FA
                            </span>
                            <span>Enabled</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security border-bottom">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-green mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Identity Verification
                            </span>
                            <span>Verified</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security border-bottom border-end">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-red mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Anti-phishing Code
                            </span>
                            <a
                              href="security.html"
                              title="setup"
                              className="text-decoration-underline"
                            >
                              Setup
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security border-bottom">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-red mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Withdrawal Whitelist
                            </span>
                            <a
                              href="security.html"
                              title="setup"
                              className="text-decoration-underline"
                            >
                              Turn on
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security border-bottom border-end">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-red mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Security Key
                            </span>
                            <a
                              href="security.html"
                              title="setup"
                              className="text-decoration-underline"
                            >
                              Setup
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security border-bottom">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-red mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Google Authenticator
                            </span>
                            <a
                              href="security.html"
                              title="setup"
                              className="text-decoration-underline"
                            >
                              Setup
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security  border-end">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-green mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Phone Number
                            </span>
                            <span>74****57</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="security ">
                        <div className="d-flex align-items-start px-2 py-3">
                          <div className="dot-green mx-2 my-2" />
                          <div className="d-flex flex-column">
                            <span className="flex-fill text-truncate">
                              Email Address{" "}
                            </span>
                            <span>ni***@gmail.com</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Row End */}
          <div className="row g-3 mb-3 row-deck">
            <div className="col-xl-4 col-xxl-5">
              <div className="card">
                <div className="card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold">Top Coin Volume</h6>
                </div>
                <div className="card-body">
                  <span className="h3 d-block mb-2">$97,431.14 USD</span>
                  {/* Progress */}
                  <div
                    className="progress rounded-pill mb-1"
                    style={{ height: 5 }}
                  >
                    <div
                      className="progress-bar chart-color1"
                      role="progressbar"
                      style={{ width: "32%" }}
                      aria-valuenow={32}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                    <div
                      className="progress-bar chart-color2"
                      role="progressbar"
                      style={{ width: "23%" }}
                      aria-valuenow={23}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                    <div
                      className="progress-bar chart-color3"
                      role="progressbar"
                      style={{ width: "13%" }}
                      aria-valuenow={13}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                    <div
                      className="progress-bar chart-color4"
                      role="progressbar"
                      style={{ width: "7%" }}
                      aria-valuenow={7}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    />
                  </div>
                  <div className="d-flex justify-content-between mb-4">
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                  {/* End Progress */}
                  <div className="table-responsive">
                    <table className="table  table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <i className="fa fa-square chart-text-color1" /> BTC
                          </td>
                          <td>5.71095643</td>
                          <td>
                            <span className="badge bg-success">+12.1%</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-square chart-text-color2" /> LTC
                          </td>
                          <td>2.409425</td>
                          <td>
                            <span className="badge bg-warning">+6.9%</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-square chart-text-color3" /> XRP
                          </td>
                          <td>0.0906654</td>
                          <td>
                            <span className="badge bg-danger">-1.5%</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <i className="fa fa-square chart-text-color4" />{" "}
                            DASH
                          </td>
                          <td>0.007653</td>
                          <td>
                            <span className="badge bg-success">1.9%</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-xxl-7">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                  <h6 className="mb-0 fw-bold">Login Activity</h6>
                  <ul
                    className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#Activity"
                        role="tab"
                      >
                        Activity
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#Devices"
                        role="tab"
                      >
                        Devices
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane fade" id="Activity">
                      <ul className="list-unstyled list mb-0">
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color1">
                            <i className="fa fa-globe" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">web</div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              122.170.109.22
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 11:00:52
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color2">
                            <i className="fa fa-globe" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">web</div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              122.170.109.22
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 11:00:20
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color3">
                            <i className="fa fa-globe" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">web</div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              122.170.109.21
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-29 10:56:22
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color4">
                            <i className="fa fa-globe" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">web</div>
                            <small className="text-muted">Pune India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              198.182.109.22
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 12:50:52
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color2">
                            <i className="fa fa-globe" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">web</div>
                            <small className="text-muted">Banglor India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              126.150.109.22
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 10:00:20
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-pane fade show active" id="Devices">
                      <ul className="list-unstyled list mb-0">
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color1">
                            <i className="fa fa-chrome" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">
                              Chrome V94.0.4606.61 (Windows)
                            </div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              122.170.109.22
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 11:00:52
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color2">
                            <i className="fa fa-mobile" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">iPhone</div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              27.57.172.87
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-23 09:03:35
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color3">
                            <i className="fa fa-firefox" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">
                              Mozila V92.0.4515.159 (Windows)
                            </div>
                            <small className="text-muted">Mumbai India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              117.99.104.150
                            </span>
                            <span className="text-muted d-block small">
                              2021-08-19 08:01:44
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color4">
                            <i className="fa fa-mobile" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">Android</div>
                            <small className="text-muted">Pune India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              118.99.104.150
                            </span>
                            <span className="text-muted d-block small">
                              2021-09-30 12:50:52
                            </span>
                          </div>
                        </li>
                        <li className="d-flex align-items-center py-2">
                          <div className="avatar rounded no-thumbnail chart-text-color3">
                            <i className="fa fa-safari" aria-hidden="true" />
                          </div>
                          <div className="flex-fill ms-3">
                            <div className="h6 mb-0">
                              Safari V84.0.4515.159 (Mac)
                            </div>
                            <small className="text-muted">Banglor India</small>
                          </div>
                          <div className="flex-end">
                            <span className="d-block text-end">
                              177.192.104.150
                            </span>
                            <span className="text-muted d-block small">
                              2021-08-19 07:01:44
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Row End */}
          <div className="row g-3 mb-3 row-deck">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between">
                  <h6 className="mb-0 fw-bold">Recent Transactions</h6>
                </div>
                <div className="card-body">
                  <table
                    id="ordertabthree"
                    className="priceTable table table-hover custom-table-2 table-bordered align-middle mb-0"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Pair</th>
                        <th>Side</th>
                        <th>Price</th>
                        <th>Executed</th>
                        <th>Fee</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>09-18 17:32:15</td>
                        <td>
                          <img
                            src="assets/images/coin/ETH.png"
                            alt=""
                            className="img-fluid avatar mx-1"
                          />
                          ETH/USDT
                        </td>
                        <td>
                          <span className="color-price-down">Sell</span>
                        </td>
                        <td>3,487.50</td>
                        <td>0.0110</td>
                        <td>0.03836250 USDT</td>
                        <td>38.36250000 USDT</td>
                      </tr>
                      <tr>
                        <td>09-18 17:31:11</td>
                        <td>
                          <img
                            src="assets/images/coin/SOL.png"
                            alt=""
                            className="img-fluid avatar mx-1"
                          />
                          SOL/USDT
                        </td>
                        <td>
                          <span className="color-price-down">Sell</span>
                        </td>
                        <td>160.33</td>
                        <td>0.75</td>
                        <td>0.12024750 USDT</td>
                        <td>120.24750000 USDT</td>
                      </tr>
                      <tr>
                        <td>09-18 08:52:04</td>
                        <td>
                          <img
                            src="assets/images/coin/ETH.png"
                            alt=""
                            className="img-fluid avatar mx-1"
                          />
                          ETH/USDT
                        </td>
                        <td>
                          <span className="color-price-up">Buy</span>
                        </td>
                        <td>3,439.20</td>
                        <td>0.0111</td>
                        <td>0.00001110 ETH</td>
                        <td>38.17512000 USDT</td>
                      </tr>
                      <tr>
                        <td>09-17 08:34:14</td>
                        <td>
                          <img
                            src="assets/images/coin/SOL.png"
                            alt=""
                            className="img-fluid avatar mx-1"
                          />
                          SOL/USDT
                        </td>
                        <td>
                          <span className="color-price-up">Buy</span>
                        </td>
                        <td>147.04</td>
                        <td>0.76</td>
                        <td>0.00076000 SOL</td>
                        <td>111.75040000 USDT</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Row End */}
        </div>
      </div>
      {/* Modal Custom Settings*/}
      <div
        className="modal fade right"
        id="Settingmodal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-sm">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Custome Settings</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body custom_setting">
              {/* Settings: Color */}
              <div className="setting-theme pb-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  <i className="icofont-color-bucket fs-4 me-2 text-primary" />
                  Template Color Settings
                </h6>
                <ul className="list-unstyled row row-cols-3 g-2 choose-skin mb-2 mt-2">
                  <li data-theme="indigo">
                    <div className="indigo" />
                  </li>
                  <li data-theme="tradewind">
                    <div className="tradewind" />
                  </li>
                  <li data-theme="monalisa">
                    <div className="monalisa" />
                  </li>
                  <li data-theme="blue">
                    <div className="blue" />
                  </li>
                  <li data-theme="cyan">
                    <div className="cyan" />
                  </li>
                  <li data-theme="green">
                    <div className="green" />
                  </li>
                  <li data-theme="orange" className="active">
                    <div className="orange" />
                  </li>
                  <li data-theme="blush">
                    <div className="blush" />
                  </li>
                  <li data-theme="red">
                    <div className="red" />
                  </li>
                </ul>
              </div>
              {/* Settings: Template dynamics */}
              <div className="dynamic-block py-3">
                <ul className="list-unstyled choose-skin mb-2 mt-1">
                  <li data-theme="dynamic">
                    <div className="dynamic">
                      <i className="icofont-paint me-2" /> Click to Dyanmic
                      Setting
                    </div>
                  </li>
                </ul>
                <div className="dt-setting">
                  <ul className="list-group list-unstyled mt-1">
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label>Primary Color</label>
                      <button
                        id="primaryColorPicker"
                        className="btn bg-primary avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label>Secondary Color</label>
                      <button
                        id="secondaryColorPicker"
                        className="btn bg-secondary avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label className="text-muted">Chart Color 1</label>
                      <button
                        id="chartColorPicker1"
                        className="btn chart-color1 avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label className="text-muted">Chart Color 2</label>
                      <button
                        id="chartColorPicker2"
                        className="btn chart-color2 avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label className="text-muted">Chart Color 3</label>
                      <button
                        id="chartColorPicker3"
                        className="btn chart-color3 avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label className="text-muted">Chart Color 4</label>
                      <button
                        id="chartColorPicker4"
                        className="btn chart-color4 avatar xs border-0 rounded-0"
                      />
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center py-1 px-2">
                      <label className="text-muted">Chart Color 5</label>
                      <button
                        id="chartColorPicker5"
                        className="btn chart-color5 avatar xs border-0 rounded-0"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/* Settings: Font */}
              <div className="setting-font py-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  <i className="icofont-font fs-4 me-2 text-primary" /> Font
                  Settings
                </h6>
                <ul className="list-group font_setting mt-1">
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-poppins"
                        defaultValue="font-poppins"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-poppins"
                      >
                        Poppins Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-opensans"
                        defaultValue="font-opensans"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-opensans"
                      >
                        Open Sans Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-montserrat"
                        defaultValue="font-montserrat"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="font-montserrat"
                      >
                        Montserrat Google Font
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item py-1 px-2">
                    <div className="form-check mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="font"
                        id="font-Plex"
                        defaultValue="font-Plex"
                        defaultChecked=""
                      />
                      <label className="form-check-label" htmlFor="font-Plex">
                        Plex Google Font
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              {/* Settings: Light/dark */}
              <div className="setting-mode py-3">
                <h6 className="card-title mb-2 fs-6 d-flex align-items-center">
                  <i className="icofont-layout fs-4 me-2 text-primary" />
                  Contrast Layout
                </h6>
                <ul className="list-group list-unstyled mb-0 mt-1">
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-switch mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-switch"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-switch"
                      >
                        Enable Dark Mode!
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-high-contrast mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-high-contrast"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="theme-high-contrast"
                      >
                        Enable High Contrast
                      </label>
                    </div>
                  </li>
                  <li className="list-group-item d-flex align-items-center py-1 px-2">
                    <div className="form-check form-switch theme-rtl mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="theme-rtl"
                      />
                      <label className="form-check-label" htmlFor="theme-rtl">
                        Enable RTL Mode!
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="modal-footer justify-content-start">
              <button
                type="button"
                className="btn btn-white border lift"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary lift">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Jquery Core Js */}
  {/* Plugin Js */}
  {/* Jquery Page Js */}
</>


  );
}

export default Home;
